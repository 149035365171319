.search-result-container {
    width: 100%;
    border: 1px solid var(--dark-grey);
    box-shadow: 0.5px 1px var(--dark-grey);
    background-color: #fff;
    z-index: 1000;

    .search-outside {
        overflow: auto;
        max-height: 50vh;
    }

    .search-item {
        max-width: 300px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding-bottom: 4px;
        border-bottom: 1px solid var(--grey);
        overflow: auto;
        border: none;

        &:hover {
            color: var(--primary-color);
        }

        &__thumnail-group {
            width: 45px;
            height: 45px;
            background-color: #fff;
            margin-left: 16px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        &__name {
            width: 100%;
            margin-left: 16px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
