.room-item {
    width: 100%;
    padding: 10px 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__action {
        cursor: pointer;
        margin-right: 0.5rem;
        &:hover {
            color: red;
        }
    }
}

.room-item + .room-item {
    margin-top: 4px;
}

.room-item-child {
    &__attribute {
        display: flex;
        align-items: center;
        padding: 0 10px 5px;
        font-size: 0.8rem;
        margin-left: 40px;
    }
}

.block {
    display: block !important;
}
