.container {
    height: 100%;
    padding: 0 12px;
    border-left: 1px solid var(--grey);
    text-transform: none;

    .head-title {
        font-size: 1.2rem;
        padding-bottom: 12px;
        margin: 16px 0;
        border-bottom: 1px solid var(--slider-bg);
    }

    .data-select {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 22px 0;
    }
}
