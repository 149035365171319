.dll-container {
    height: 100%;

    padding: 20px 40px;
    overflow: auto;

    .dll-upload-container {
        height: 100%;
        padding: 0 10px;
        margin-top: -4px;

        // @include responsive_425() {
        //     margin-top: 40px;
        // }

        // @include responsive_768() {
        //     margin-top: 40px;
        // }

        .upload-title {
            font-size: 1.2rem;
        }

        button {
            margin-top: 15px;
        }
    }
}
