.language-content {
    color: black;
    padding: 3px 5px;
    display: flex;
    width: 100%;

    &_border {
        border: 1px solid rgb(85, 85, 85);
        max-width: 80px;
        border-radius: 25px;
    }

    span {
        font-size: 2rem;
        border-radius: 15px;
    }

    .country-name {
        text-align: center;
        padding-left: 5px;
        font-size: 1.2rem;
    }
}
