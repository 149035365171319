// -----------------------------------------------------
// Form new-field
// -----------------------------------------------------

.input {
    position: relative;

    &__label {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        padding: 0 8px;
        margin: 0 2px;
        white-space: nowrap;
        transform: translate(0, 0);
        transform-origin: 0 0;
        transition: transform 120ms ease-in;
        font-weight: 600;
        line-height: 1.4375em;
        letter-spacing: 0.00938em;
        color: var(--primary-color);
        opacity: 0.6;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(133% - 24px);
        background-color: #ffffff;
        border-radius: 25px;
        pointer-events: none;
        user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        z-index: 1;
    }

    &__actions {
        display: flex;
        position: absolute;
        align-items: center;
        top: -8px;
        right: 0;
        padding: 8px 0 8px 4px;

        i {
            padding: 2px;
            margin-right: 4px;

            &:hover {
                color: var(--primary-color);
                cursor: pointer;
            }
        }
    }

    &__has-value {
        & + .input__label {
            opacity: 1;
            -webkit-transform: translate(-4px, -13px) scale(0.8);
            -ms-transform: translate(-4px, -13px) scale(0.8);
            transform: translate(-4px, -13px) scale(0.8);
            left: 12px;
            top: -6px;
            padding-block: 0;
            padding-inline: 4px;
        }
    }

    .err-mess {
        margin-left: 6px;
        font-weight: 400;
        color: red;

        .err-mess > span {
            font-size: 0.8rem;
        }
    }
}

.input-custom {
    height: 35px;

    &:focus {
        & + .input__label {
            opacity: 1;
            -webkit-transform: translate(-4px, -13px) scale(0.8);
            -ms-transform: translate(-4px, -13px) scale(0.8);
            transform: translate(-4px, -13px) scale(0.8);
            left: 12px;
            top: -6px;
            padding-block: 0;
            padding-inline: 4px;
        }
    }

    &::placeholder {
        color: var(--primary-color);
    }
}

.popover-modal {
    width: 280px;
    height: 150px;
    z-index: 1000;

    .switch-btn {
        margin: 20px 0;
    }
}

.btn-add-field {
    height: 32px;
    border-radius: 10px;
    font-size: 14px;
    padding: 4px;
    font-weight: 400;
}

.edit-btn {
    margin-left: 10px;

    &:hover i {
        color: var(--primary-color);
        cursor: pointer;
    }
}

.actions-new-field {
    display: flex;
    align-items: center;
    position: absolute;
    right: 1%;
    bottom: 1px;
    padding: 4px;

    i {
        &:hover {
            color: var(--primary-color);
            cursor: pointer;
        }
    }
}
