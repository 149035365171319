.modal-item {
    height: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
    position: relative;
    padding: 8px 22px;
    margin-bottom: 5px;
    font-size: 1.2rem;
    cursor: pointer;

    &:hover,
    &:hover .modal-item__name {
        background-color: #c5c4c4;
        color: var(--primary-color);
    }

    &__content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-items: center;
    }

    &__thumnail-group {
        width: 150px;
        height: 150px;
        padding-right: 8px;
        border-right: 1px solid #ddd;
    }

    &__name {
        max-width: 50%;
        margin-left: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__delete {
        i {
            position: absolute;
            right: 6%;
            top: 42%;
            font-size: 1.3rem;
            color: var(--dark-grey);

            &:hover {
                color: red;
            }
        }
    }
}

.title-export {
    margin-bottom: 10px;
}

.btn-export {
    border-radius: 5px;
}

.active {
    color: var(--primary-color);
}
