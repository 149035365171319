.user-dropdown {
    display: flex;
    align-items: center;
    max-width: 150px;
    margin-left: 10px;
    color: var(--text-grey);
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
        color: var(--primary-color);
    }

    &-name {
        width: 90%;
        height: 30px;
        padding: 0 5px;
        font-size: 1.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.menu-item {
    min-width: 120px;
    font-weight: 500;
    font-size: 1rem;
    padding: 12px 0;

    &__path {
        display: inline-block;
        color: black;
        width: 100%;
        &:hover {
            color: black;
        }
    }
}

.menu-custom {
    li {
        padding: 0;
    }
}
