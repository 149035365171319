@import 'src/styles/mixins.scss';

.input-custom {
    width: 100%;
    height: 35px;
    border-radius: 5px;

    &:focus {
        & + .input__label {
            opacity: 1;
            transform: translate(0.25rem, -80%) scale(0.8);
            background-color: #fff;
            padding: 5px;
        }
    }

    &:hover,
    &:active,
    &:focus {
        border-color: var(--text-grey);
    }

    &::placeholder {
        color: var(--primary-color);
    }

    @include responsive_425() {
        width: 100% !important;
    }

    @include responsive_768() {
        width: 100% !important;
    }

    @include responsive_992() {
        width: 100% !important;
    }
}

.body-modal-upload {
    display: flex;
    justify-content: space-around;
    min-height: 384px;

    @include responsive_425() {
        display: block;
    }

    @include responsive_768() {
        display: block;
    }

    @include responsive_992() {
        display: block;
    }

    @include responsive_1024() {
        display: block;
    }

    @include responsive_1200() {
        display: flex;
    }

    .child-w-30 {
        width: 30%;
        margin: 0 30px;
    }

    .child-w-40 {
        width: 40%;
        position: relative;
    }

    .child-pad-side-30 {
        padding: 0 30px;
    }

    &__child {
        @include responsive_425() {
            width: 100% !important;
            margin: 0 0 10px !important;
        }

        @include responsive_768() {
            width: 100% !important;
            margin: 0 0 10px !important;
        }

        @include responsive_992() {
            width: 100% !important;
            margin: 0 0 10px !important;
        }

        @include responsive_1024() {
            width: 100% !important;
            margin: 0 0 10px !important;
        }

        @include responsive_1200() {
            width: 40% !important;
            margin: 0 0 10px !important;
        }

        @include responsive_1320() {
            width: 30% !important;
            margin: 0 0 10px !important;
        }

        .field-checkbox {
            margin-bottom: 8px;
        }

        .save-time {
            display: flex;
            margin-top: 10px;
            padding: 10px 6px;
            border-top: 1px solid var(--grey);

            h4 {
                margin-left: 10px;
            }

            span {
                margin-left: 6px;
                text-transform: none;
            }
        }

        .atten-save-time {
            margin-left: 20px;
            font-size: 0.8rem;
            color: var(--text-grey);
        }
    }
}

.upload-avatar {
    max-height: 145px;
    height: 50%;
    padding: 16px 0;
    transition: 0.2s;
    border-radius: 14px;
    text-align: center;

    .image {
        transition: 0.2s;
        opacity: 0.6;
    }

    &:hover {
        border-color: var(--text-grey);
        transition: 0.2s;

        .image {
            opacity: 1;
            transition: 0.2s;
        }
    }

    @include responsive_425() {
        height: 14%;
        padding: 16px 0;
        margin-bottom: 20px;
        max-width: 104px;
    }

    @include responsive_768() {
        height: 18%;
        padding: 2px 0;
        margin-bottom: 20px;
        max-width: 104px;
    }

    @include responsive_992() {
        height: 18%;
        padding: 0;
        margin-bottom: 20px;
        max-width: 104px;
    }

    @include responsive_1024() {
        position: absolute;
        right: 0;
        width: 28%;
        height: 50%;
        padding: 30px 0;
    }

    @include responsive_1200() {
        position: absolute;
        right: 0;
        width: 28%;
        height: 50%;
        padding: 30px 0;
    }

    @include responsive_1320() {
        position: absolute;
        right: 0;
        width: 28%;
        height: 50%;
        padding: 30px 0;
    }
}

.boder-upload {
    border: 1px solid var(--grey);
}

.input {
    position: relative;

    &__label {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0 8px;
        margin: 0 2px;
        white-space: nowrap;
        transform: translate(0, 0);
        transform-origin: 0 0;
        transition: transform 120ms ease-in;
        font-weight: bold;
        line-height: 1.2;
        color: var(--primary-color);
        opacity: 0.6;
    }

    &__actions {
        display: flex;
        position: absolute;
        align-items: center;
        top: -8px;
        right: 0;
        padding: 8px 0 8px 4px;

        i {
            padding: 2px;
            margin-right: 4px;

            &:hover {
                color: var(--primary-color);
                cursor: pointer;
            }
        }
    }

    &__has-value {
        & + .input__label {
            opacity: 1;
            transform: translate(0.25rem, -80%) scale(0.8);
            background-color: #fff;
            padding: 5px;
        }
    }
}

.form-item {
    width: 100%;
    label {
        font-weight: 500;
    }
}

.new-field {
    position: relative;
    padding-bottom: 60px;

    &__btn {
        position: absolute;
        right: 0;
        outline: 0;
        border: none;
        text-transform: capitalize;
        &:hover {
            color: var(--primary-color);
        }
    }
}

.customer-container {
    position: relative;
    margin-bottom: 20px;
}

.popover-modal {
    width: 280px;
    max-height: 170px;
    z-index: 1000;

    .switch-btn {
        margin: 20px 0;
    }
}

.custom-btn {
    border-radius: 10px;
    opacity: 0.9;
    background-color: var(--primary-color);

    &:focus,
    &:active,
    &:hover {
        opacity: 1;
        color: black;
        background-color: var(--primary-color);
    }
}

.list-file-upload {
    max-height: 200px;
    overflow: auto;
    margin-top: 10px;

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2px;
        border-radius: 5px;

        input {
            cursor: text !important;
        }

        .item-name {
            margin-left: 4px;

            &:hover {
                color: var(--primary-color);
                cursor: pointer;
            }
        }

        &:hover {
            background-color: rgb(193, 190, 190, 0.5);
        }
    }
}

.attention {
    width: 100%;
    padding: 0 8px;
    border-radius: 4px;
    background-color: rgba(255, 237, 170, 1);
    color: var(--primary-color);
    text-align: center;
    font-size: 0.8rem;
}

.edit-btn {
    margin-left: 10px;

    &:hover i {
        color: var(--primary-color);
        cursor: pointer;
    }
}

.tabs-list-selected {
    width: 100%;
    max-height: 80%;
    margin-top: 10px;
    overflow-y: overlay;

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2px;
        margin-bottom: 10px;

        border: 1px solid var(--text-grey);
        border-radius: 5px;
        opacity: 0.89;

        .name {
            margin-left: 4px;
            margin-right: 2px;
            text-decoration: none;
            color: var(--text-grey);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        &:hover {
            border-color: var(--primary-color);
            opacity: 1;
            .name {
                color: var(--primary-color);
            }
        }
    }
}

.remove-btn {
    border: none;
    outline: 0;
    padding: 4px;
    margin-right: 4px;
    background-color: transparent;

    &:hover {
        color: var(--primary-color);
        cursor: pointer;
    }
}

.actions-new-field {
    display: flex;
    align-items: center;
    position: absolute;
    right: 1%;
    bottom: 1px;
    padding: 4px;

    i {
        &:hover {
            color: var(--primary-color);
            cursor: pointer;
        }
    }
}

.information-container {
    .fixed-information {
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--grey);
    }

    .information {
        display: flex;
        margin-bottom: 8px;

        span {
            margin-left: 8px;
            text-transform: none;
        }

        .info {
            margin-left: 8px;
            margin-right: 22px;
        }
    }

    .project-tab {
        border: none;
        justify-content: flex-start;
    }

    h4 {
        font-weight: 600;
    }
}

.err-mess {
    margin-left: 6px;
    font-weight: 400;
    color: red;

    .err-mess > span {
        font-size: 0.8rem;
    }
}
