.footer-container {
    max-height: 40px;
    font-size: 1rem;
    border-top: 1px solid var(--grey);

    &__content {
        color: var(--text-grey);
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
        line-height: 40px;
    }
}
