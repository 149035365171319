// --------------------------------------------------------
// FloorSettingsChildList
// --------------------------------------------------------

.action {
    font-size: 12px;
    &:hover {
        color: var(--primary-color);
        cursor: pointer;
    }
}

.color-circle-parent {
    display: block;
    position: relative;
    width: 24px;
    height: 24px;
    border: 2px solid var(--dark-grey);
    background-color: white;
    border-radius: 20px;
    margin-top: 4px;

    .color-circle-child {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 16px;
        height: 16px;
        border: 1px solid var(--text-grey);
        border-radius: 20px;
    }
}

// --------------------------------------------------------
// FloorSettingsModal
// --------------------------------------------------------

$sizeHeight: 240px;
.table-container {

    border: 1px solid #e7e7e7;
    border-radius: 8px;
    overflow: clip; // ** Do not change this! **
    height: $sizeHeight;

    display: flex;

    .table-room-list {
        margin: 0;
        max-height: $sizeHeight;
        text-transform: none;
    }
}

.color-container {
    text-transform: none;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, minmax(50px, auto));
    place-content: center;
    gap: 24px;

    .color-picker {
        width: 100%;
        height: 150px;
    }

    .color-name {

        width: 100%;
        text-align: center;
        margin-bottom: 24px;
        font-weight: 600;
        font-size: 1.25rem;
    }
}
