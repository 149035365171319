.MenuControll_item {
    width: 158px;
    height: 30px;
    padding: 4px 16px;
    outline: none;
    border: none;
    background-color: transparent;
    text-align: left;
    text-transform: capitalize;
    font-size: 1rem;
    cursor: pointer;
}

.hover {
    &:hover {
        color: var(--primary-color);
    }
}
