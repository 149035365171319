@import 'src/styles/mixins.scss';

.sidebar-container {
    height: 100%;
    padding: 14px 20px;
    color: var(--text-grey);
    font-size: 1.2rem;
    z-index: 2000;

    @include responsive_320() {
        width: 50vh;
        height: 32vh;
        padding: 0 12px;
        background-color: transparent;
        border: none;
    }

    @include responsive_425() {
        width: 50vh;
        height: 32vh;
        padding: 0 12px;
        background-color: transparent;
        border: none;
    }

    @include responsive_768() {
        width: 50vh;
        height: 32vh;
        padding: 0 12px;
        background-color: transparent;
        border: none;
    }

    @include responsive_992 {
    }

    @include responsive_1200 {
    }

    .sidebar-item {
        margin-bottom: 42px;
        text-transform: capitalize;
        cursor: pointer;

        &__title {
            padding: 8px 0;
        }

        .sidebar-button-group {
            margin-bottom: 10px;
        }

        &__button {
            width: 100%;
            margin-bottom: 3px;
            padding: 5px 15px;
            border-radius: 5px;
            font-size: 1rem;
            font-weight: 400;
            color: var(--text);
            cursor: pointer;

            i {
                color: var(--primary-color);
            }

            &-active,
            &:hover {
                background-color: var(--secondary-color);
            }
        }
    }
}
