.header {
    background-color: #eb344a;
    background-image: linear-gradient(to right, #eb344a, #f45943);
}

.home-container {
    min-height: 80vh;
    height: 100%;
    overflow: auto;

    .home-content {
        height: 100%;
    }
}
