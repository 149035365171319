@import 'normalize.css';
// @import 'antd/dist/antd.css';
@import 'antd/dist/antd.variable.min.css';
@import url('https://developer.api.autodesk.com/modelderivative/v2/viewers/style.min.css');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap');
@import 'src/styles/variable';
@import 'src/styles/mixins';
@import 'flag-icons/css/flag-icons.min.css';

:root {
    --bodybg: #eee;
    --primary-color: #f44c45;
    --dark-grey: #b4b4b4;
    --grey: #d6d6d6;
    --placeholder: #969696;
    --white: #fff;
    --text: #333;
    --slider-bg: #ddd;
    --login-cta-hover: #ec374b;
    --text-grey: #666;
    --secondary-color: #dac266;
}

.App {
    height: 100vh;
    @include responsive_425 {
        height: 100%;
    }

    @include responsive_768 {
        height: 100%;
    }

    @include responsive_992 {
        height: 100%;
    }

    @include responsive_1200 {
        height: 100vh;
    }
}

body {
    // font-family: 'Raleway', sans-serif;
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
    box-sizing: border-box;
    text-transform: capitalize;
}

ul,
li {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
}

p {
    margin: 0;
}

.mb-25 {
    margin-bottom: 25px;
}

.h-100 {
    height: 100% !important;
}

.top-40 {
    top: 40% !important;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    @include responsive_425 {
        max-width: 700px;
    }

    @include responsive_768 {
        max-width: 720px;
    }

    @include responsive_992 {
        max-width: 960px;
    }

    @include responsive_1200 {
        max-width: 100%;
    }
}

#guiviewer3d-toolbar {
    display: none;
}

.full-screen {
    width: 100vw;
    height: 100vh;

    position: relative;
}

.center-width-height {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.space-between {
    justify-content: space-between;
}

.loading-custom {
    span {
        i {
            background-color: var(--primary-color);
        }
    }
}

.animation {
    animation: Animation 0.3s linear;

    @keyframes Animation {
        from {
            opacity: 0.5;
        }
        to {
            opacity: 1;
        }
    }
}

.ant-input-group-addon {
    display: none;
}

.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
    outline: none;
    outline-offset: 0;
}

.ant-skeleton {
    width: 100% !important;
    height: 100% !important;
}

.ant-spin-container {
    width: 100%;
    height: 100%;
}

.ant-spin-nested-loading {
    width: 100%;
    height: 100%;
}

.ant-skeleton-element .ant-skeleton-image {
    width: 100% !important;
    height: 100% !important;
}
/**
 * Customizes default ant-radio
 */
.ant-radio-inner::after {
    background-color: var(--primary-color);
}
.ant-radio-checked .ant-radio-inner {
    border-color: var(--primary-color);
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
    border-color: var(--primary-color);
}
.ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 2px rgba(244, 76, 69, 0.12);
}
/**
 * Customizes default ant-switch-checked
 */
.ant-switch-checked {
    background-color: var(--primary-color);
}
/**
 * Customizes default ant-select
 */
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(244, 76, 69, 0.2);
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--primary-color);
    border-right-width: 1px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px !important;
}
.ant-select-single .ant-select-selector {
    border-radius: 5px !important;
}

/**
 * Customizes default ant-checkbox
 */
.ant-checkbox:hover .ant-checkbox-inner {
    border-color: var(--primary-color);
    outline: none;
}
.ant-checkbox-input {
    &:hover .ant-checkbox-inner {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
    }
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--primary-color);
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

/**
 * Customizes default ant-input, that includes:
 * - Normal input
 * - Search input
 */
.ant-input {
    border-radius: 5px;
}
.ant-input:hover {
    border-color: var(--primary-color);
}
.ant-input:focus,
.ant-input-focused {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(244, 76, 69, 0.2);
    border-right-width: 1px;
    outline: 0;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(244, 76, 69, 0.2);
    border-right-width: 1px;
    outline: 0;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: var(--primary-color);
    border-right-width: 1px;
    z-index: 1;
}
.ant-input-affix-wrapper:hover {
    border-color: var(--primary-color);
    border-right-width: 1px;
}
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
    border-color: var(--primary-color);
}
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 5px;
}

#components-layout-demo-top .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

//Custom upload list
.ant-upload-list {
    border: none;
}

.ant-upload-list-picture-card .ant-upload-list-item-info::before {
    left: 0;
}

.ant-upload.ant-upload-select-picture-card {
    max-width: 100%;
    background-color: transparent;
    height: 100%;
    border: none;
}

.ant-upload-list-picture-card .ant-upload-list-item {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    border-color: var(--text);

    @include responsive_425() {
        position: relative;
    }

    @include responsive_768() {
        position: relative;
    }

    @include responsive_992() {
        position: relative;
    }
}

.ant-table-wrapper {
    max-width: 100%;
    overflow: auto;
    margin: 4px 10px;
    background-color: var(--grey);
    text-align: center;
    border-right: 1px solid var(--grey);
}

.ant-table-title {
    font-size: 1.2rem;
    font-weight: 500;
}

.ant-table-pagination.ant-pagination {
    display: none;
}

.site-layout-content {
    width: 100%;
    min-height: 450px;
}

#forgeViewer {
    position: relative;
    background-color: #d9edf7;
    margin-left: auto;
    margin-right: auto;
    //border: 1px rgb(241, 89, 0) solid;
    border: 1px solid var(--grey);
    width: 100%;
    height: 100%;
    // height: calc(100vh - 180px);
}

/**
 * Customizes default ant-button
 */
.btn-custom {
    display: inline-block;
    height: 100%;
    text-decoration: none;
    border-radius: 50px;
    padding: 6px 20px;
    box-sizing: border-box;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    transition: linear 0.2s;
    color: var(--white);
    text-decoration: none;
    border: 1px solid var(--primary-color);
    background: var(--primary-color);
    cursor: pointer;

    &:hover,
    &:focus {
        border-color: var(--login-cta-hover);
        background: var(--login-cta-hover);
        color: black;
    }

    &_full-width {
        width: 100%;
    }
}
.btn-custom-large {
    height: 52px;
}
.btn-custom-small {
    height: 100%;
    text-decoration: none;
    border-radius: 30px;
    padding: 8px 10px;
    box-sizing: border-box;
    justify-content: center;
    font-size: 12px;
    font-weight: 300;
}
.btn-custom-ghost {
    display: inline-block;
    height: 100%;
    text-decoration: none;
    border-radius: 50px;
    padding: 6px 20px;
    box-sizing: border-box;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    transition: linear 0.2s;
    color: var(--text-grey);
    text-decoration: none;
    border: 1px solid var(--text-grey);
    background: white;
    cursor: pointer;

    &:hover,
    &:focus {
        border-color: var(--primary-color);
        color: var(--primary-color);
    }

    &_full-width {
        width: 100%;
    }
}

.ant-select-single .ant-select-selector {
    border-radius: 10px;

    &:hover,
    &:active,
    &:focus,
    &::selection {
        border-color: var(--primary-color) !important;
    }
}

//Custom Breadcrumb
.ant-breadcrumb ol li + li {
    max-width: 50%;
    font-size: 1rem;
    font-weight: 300;
    text-align: left;
    color: var(--text-grey);
    margin-bottom: 6px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

//Custom radio input
.ant-radio-inner,
.ant-radio-checked {
    border-color: var(--primary-color) !important;

    &::after {
        border-color: var(--primary-color);
        border: var(--primary-color);
        background-color: var(--primary-color);
    }
}

// Custom Srollbar

html *::-webkit-scrollbar {
    border-radius: 0;
    width: 4px;
}

html *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.15);
}

html *::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/**
 * Styling for FloorSettingTool Modal
 */
div[data-id='fsc-modal'] {
    div.ant-modal-wrap {
        z-index: 10;
        top: 180px;
        right: 24px;
        bottom: unset;
        left: unset;
        overflow: visible;
        height: 0;
    }
}

/**
 * Styling for Viewer PDF Modal
 */
div[data-id='modal-viewer-pdf'] {
    div.ant-modal-wrap {
        z-index: 10;
        top: 180px;
        right: 24px;
        bottom: unset;
        left: unset;
        overflow: visible;
        height: 0;
    }
}
