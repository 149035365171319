.tool-container {
    height: 100%;
    position: relative;

    .notify {
        max-width: 400px;
        position: absolute;
        top: -24px;
        left: 40%;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid transparent;
        text-align: center;
        box-shadow: 4px 4px 12px;
        animation: 0.2s linear animation;
        background-color: var(--bodybg);
        z-index: 1000;

        &__title {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            border-radius: 10px;
            margin-bottom: 6px;

            h4 {
                margin: 0;
                color: var(--primary-color);
                text-transform: capitalize;
                font-size: 1.5rem;
            }
        }

        p {
            font-size: 1rem;
            font-style: italic;
            text-align: center;
            i {
                padding-right: 4px;
                color: var(--primary-color);
            }
        }

        @keyframes animation {
            from {
                top: -50px;
                opacity: 0.6;
            }
            to {
                top: -44px;
                opacity: 1;
            }
        }
    }

    .noti-wrong-unit {
        min-width: 100%;
        min-height: 40px;
        top: 6%;
        left: 0;
        font-size: 1rem;
        background-color: rgba(242, 216, 50, 0.6);
        color: var(--primary-color);
        border-radius: 0;
        animation: 0.2s linear animation2;

        @keyframes animation2 {
            from {
                opacity: 0.8;
            }
            to {
                opacity: 1;
            }
        }
    }

    .tool-header {
        position: relative;
        margin-left: 0px;
        display: flex;
        align-items: center;
        background-color: var(--bodybg);
        padding: 10px 30px;
        font-size: 1rem;

        &__item,
        &__save-btn {
            position: relative;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 5px 10px 5px 10px;
            cursor: pointer;
            border-radius: 6px;

            &:hover {
                background-color: var(--primary-color);
                color: white;
            }
        }

        &__active {
            background-color: var(--primary-color);
            color: white;
        }

        &__save-btn {
            margin-left: 4px;
            padding: 5px 6px;
            border-left: 1px solid var(--grey);
            border-right: 1px solid var(--grey);
            border-radius: 4px;

            &:hover {
                background-color: transparent;
                border-color: #9f9b9b;
            }
        }
    }

    .tool-viewer {
        &__file {
            border-bottom: 1px solid var(--grey);
            display: flex;
            font-size: 1rem;
            .file-item {
                cursor: pointer;
                padding: 3px 8px;
                border-right: 1px solid var(--grey);

                button {
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                }

                &__close {
                    margin-left: 5px;
                    padding: 0 6px;
                }
            }
            .file-active {
                background-color: var(--primary-color);
                color: white;
            }
        }
    }
}
