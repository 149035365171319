.horizontal {
    display: inline-block !important;
    &_item {
        margin-left: 8px;
    }
}

.big-size {
    width: 75px !important;
    height: 75px !important;
    margin: 0 10px 10px 0 !important;

    i {
        margin: auto 0;
        max-height: 30px;
    }
}

.item-button {
    display: flex;
    margin-right: 12px;
    margin-bottom: 12px;
    font-size: 0.75rem;
    max-width: 105px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    text-transform: capitalize;
    cursor: pointer;

    &__name {
        width: 100%;
        max-height: 30px;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.5;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    i {
        font-size: 1rem;
        margin: 4px 0px;
    }

    &:hover,
    &__active {
        background-color: var(--primary-color);
        color: white;
    }
}
