.header-container {
    max-height: 60px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    background-color: #fff;
    border-bottom: 1px solid var(--grey);

    &__content {
        height: 100%;
        padding: 10px 36px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header-logo {
            height: 100%;
            text-align: center;
            display: table-cell;

            &__brand {
                height: 100%;
                padding-top: 0.3125rem;
                padding-bottom: 0.3125rem;
                margin-right: 1rem;
                font-size: 1.25rem;
                text-decoration: none;
                white-space: nowrap;
                vertical-align: middle;
                img {
                    vertical-align: middle;
                }
            }
        }

        .header-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 320px;
            height: 100%;
            overflow: hidden;
        }
    }
}
