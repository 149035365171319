.button-help {
    border: none;
    outline: none;
    padding: 6px 0 2px;

    i {
        font-size: 1rem;
        &:hover {
            cursor: pointer;
        }
    }
}

.tools-container {
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-style: italic;
    cursor: text;

    .item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 1rem;
        font-weight: 500;
        color: var(--text);

        img {
            width: 45px;
            height: 40px;
        }

        .content {
            margin-left: 8px;
        }
    }

    .item + .item {
        margin-top: 10px;
    }
}
