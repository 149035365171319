@import 'src/styles/mixins.scss';

.disabled {
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
}

.list-file-group__item {
    position: relative;
    width: 240px;
    height: 240px;
    border: 1px solid var(--grey);
    border-radius: 20px;
    padding: 20px;
    margin: 15px 30px 15px 0;
    background-color: var(--white);
    float: left;
    cursor: pointer;

    .active {
        display: block !important;
    }

    &__check-box {
        display: none;
        position: absolute;
        top: -10px;
        left: 0;

        input {
            display: none;
        }
    }

    &:hover {
        border: 1px solid #dac266;
        box-shadow: inset 0 2px 20px #dac266;
    }

    &:hover &__button,
    &:hover &__check-box {
        display: block;
    }

    @include responsive_425() {
        margin: 5px auto 15px;
    }

    @include responsive_768() {
        margin: 15px auto 15px;
    }

    @include responsive_992() {
        margin: 15px 30px 15px 0;
    }

    @include responsive_1024() {
        margin: 5px 25px 10px 0;
    }

    &__button {
        display: none;
        position: absolute;
        width: 20px;
        height: 20px;
        right: 8px;
        top: 6px;
        padding: 0;
        border: 1px solid transparent;
        background-color: transparent;
        font-size: 1rem;
        line-height: 1.5;
        z-index: 999;
        cursor: pointer;

        i {
            color: var(--text);
            font-size: 1.2rem;
        }

        @include responsive_425() {
            display: block;
        }

        @include responsive_768() {
            display: block;
        }

        @include responsive_992() {
            display: block;
        }

        @include responsive_1024() {
        }

        &:hover .ant-dropdown-menu {
            color: #fff;
            background-color: #5c636a;
            border-color: #565e64;
        }

        &:focus {
            display: block;
        }
    }

    .thumbnail-group {
        width: 100%;
        height: 100%;
        background-color: transparent;
    }

    .item-name {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        max-width: 200px;
        width: 100%;
        margin: auto;
        font-size: 0.875rem;
        color: #666;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 200px;
    }
}
