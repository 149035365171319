.file-thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;

    &__error-icon {
        width: 100%;
        font-size: 5rem;
        color: rgb(243, 69, 69);
    }

    &__error-title,
    &__uploading-title,
    &__processing-title {
        position: absolute;
        bottom: 50px;
        width: 100%;
        font-size: 1rem !important;
    }

    &__error-title {
        color: rgb(243, 69, 69);
    }

    &__uploading-title,
    &__processing-title {
        color: #7474ff;
    }
}
