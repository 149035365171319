.tool-viewer-container {
    display: flex;
    max-width: 100%;
    height: 100%;
    position: relative;
    color: var(--text-grey);

    max-height: 100vh;

    .sub-tools {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        z-index: 3;
        width: 20%;
        min-width: 350px;
        position: absolute;
        background-color: white;
        height: 100%;
        font-size: 1rem;
        padding: 15px 20px;
        display: block;
        overflow: auto;

        .sub-tools-back {
            border-radius: 5px;
            padding: 5px;
            display: inline;
            width: auto;
            cursor: pointer;

            &:hover {
                background-color: var(--grey);
            }
        }

        .sub-tools-content {
            position: relative;
            height: 90%;

            &__title {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &__btn-group {
                margin: 10px 0;

                .main {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    h2 {
                        margin: 0;
                    }
                }
            }

            &__option-unit {
                display: flex;
            }

            &__action {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;
            }
            .margin-top {
                margin-top: 50%;
            }
            &__nodata {
                margin-top: 10px;
                text-align: center;

                .check-all-btn {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px 0;

                    h4 {
                        margin: 0;
                        font-size: 1.2rem;
                    }
                }

                .sub-tools-room {
                    text-align: left;
                    padding-bottom: 5px;
                    margin-bottom: 5px;
                    border-bottom: 1px solid var(--text-grey);

                    &__sort-button {
                        display: flex;
                        align-items: center;
                        padding: 0;
                        border: none;
                        outline: none;
                        cursor: pointer;
                        background-color: transparent;
                        margin-bottom: 10px;

                        label {
                            padding-right: 8px;
                        }

                        .title {
                            margin: 0;
                            font-size: 1.2rem;
                            cursor: pointer;
                        }
                    }

                    .device-item {
                        display: none;
                        margin-left: 4px;

                        .device-head {
                            display: flex;

                            .device-title {
                                padding: 0 10px;
                                font-size: 0.9rem;
                                color: #544848;
                                text-transform: none;
                                cursor: default;
                            }

                            i:hover {
                                color: var(--primary-color);
                            }
                        }

                        .device-radius {
                            margin-left: 30px;
                            font-size: 0.9rem;
                        }

                        .btn-remove {
                            height: 50%;
                            border: none;
                            outline: none;
                            background-color: transparent;

                            &:hover i {
                                color: var(--primary-color);
                                cursor: pointer;
                            }
                        }
                    }

                    .device-item + .device-item {
                        border-top: 1px solid var(--grey);
                        padding-top: 6px;
                        margin-top: 6px;
                    }

                    .open {
                        display: block;
                    }
                }
            }

            .sub-tools-unit {
                margin-bottom: 10px;
                border-bottom: 1px solid var(--grey);

                &__option-unit {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    max-height: 30px;
                    padding: 4px 8px;
                    margin-bottom: 10px;
                    border-radius: 10px;
                    color: #666;
                    background-color: var(--bodybg);
                    font-size: 0.85rem;
                    cursor: pointer;
                }
                span {
                    white-space: nowrap;
                }
            }

            &__list {
                position: absolute;
                width: 100%;
            }

            &__list:last-child {
                padding-bottom: 16px;
            }

            .symbol-tool-list {
                margin-top: 20px;

                .symbol-tool-group + .symbol-tool-group {
                    border-top: 1px solid var(--grey);
                    padding-top: 15px;
                }

                .symbol-tool-group {
                    .symbol-tool-item {
                        display: flex;
                        align-items: center;
                        margin-bottom: 2px;
                        font-size: 0.9rem;
                        font-weight: 800;
                        color: var(--text-grey);

                        &__img {
                            width: auto;
                            height: 35px;
                            margin-left: 5px;
                        }
                        &__name {
                            padding-left: 5px;
                        }

                        .trash-can {
                            &:hover {
                                color: var(--primary-color);
                            }
                        }
                    }
                }
            }
        }
    }

    .menu-tools {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        z-index: 2;
        width: 20%;
        position: absolute;
        background-color: white;
        min-width: 350px;
        max-height: 80vh;
        height: 100%;
        font-size: 1rem;
        padding: 20px;
        overflow: auto;

        &__img {
            width: 100%;
            padding: 0 50px;
        }

        &__nodata {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__title {
            margin-bottom: 35px;

            h5 {
                margin-bottom: 8px;
                font-size: 1.25rem;
                font-weight: 500;
                color: var(--text-grey);
            }

            span {
                margin-bottom: 16px;
            }
        }

        &__item {
            margin-bottom: 35px;
            .item-title {
                display: flex;
                align-items: center;
                max-height: 30px;
                padding: 8px;
                margin-bottom: 8px;
                border-radius: 10px;
                color: #666;
                background-color: var(--bodybg);
                font-size: 0.75rem;
                cursor: pointer;
                width: 100%;
                outline: none;
                border: none;
            }

            .item-tool-group {
                margin-left: 6px;
                list-style-type: none;

                .item-title {
                    .sort-button {
                        padding: 0;
                        margin-top: -6px;
                        border: none;
                        outline: none;
                        cursor: pointer;

                        .rotate-right {
                            margin-top: 8px;
                            transform: rotate(270deg);
                        }
                    }

                    .item-name {
                        margin-left: 8px;
                    }
                }

                .adjust-btn {
                    border: none;
                    outline: none;
                    background-color: transparent;
                    margin-top: 4px;

                    & > * {
                        margin: 0;
                    }
                }

                .tree-item {
                    .hide {
                        display: none;
                    }

                    &__children {
                        display: flex;
                        flex-wrap: wrap;
                        margin-left: 4px;
                        margin-bottom: 10px;
                    }
                }
            }

            // .item-subtitle {
            //     padding: 3px 10px;
            //     background-color: var(--bodybg);
            //     border-radius: 5px;
            // }

            .item-buttom-group {
                display: flex;
                justify-content: flex-start;
                list-style: none;
                padding: 0;
                margin-left: 12px;
                margin-top: 12px;
                flex-wrap: wrap;
            }
        }
    }
}

.modal-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .modal-img {
        min-width: 210px;
        min-height: 210px;
        margin-top: 4px;
        min-width: 200px;
        padding: 4px;
        border: 1px solid var(--text-grey);
        border-radius: 5px;
    }
    .modal-info {
        margin-left: 24px;
        font-weight: 500;
        font-size: 0.9rem;

        p {
            margin-bottom: 5px;

            span {
                margin-left: 8px;
                font-weight: 600;
            }
        }
    }
}
.divider {
    margin-block: 4px;
    border-width: 2px;
}

.floor-settings-title {
    margin-bottom: 8px;
    font-weight: 600;
    color: var(--text-grey);
}

.unit-selector {
    display: inline-block;
    margin-left: 8px;
    border-radius: 5px;
    background-color: rgb(241, 241, 241);
}
