.container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        margin-bottom: 40px;
    }

    h2 {
        letter-spacing: 0.1rem;
    }
}
