.list-file-size {
    width: 100%;
    height: 100%;
}

.menu-tool {
    width: 100% !important;
    height: 100% !important;
}

.menu-tool,
.upload-page {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #fff;
    z-index: 1000;
}

.overlay {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.3);
}
