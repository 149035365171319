@import 'src/styles/mixins.scss';

.main-home-container {
    width: 100%;
    height: 100%;
    font-size: 1.3rem;
    padding: 0 12px;
    color: var(--text-grey);
    border-left: 1px solid var(--grey);

    .none {
        display: none !important;
    }

    .main-home-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 98%;
        margin: 16px 0;
        border-bottom: 1px solid #ddd;

        .action-group {
            display: flex;
            justify-content: flex-end;
            font-size: 1rem;

            & > * {
                margin-bottom: 6px;

                @include responsive_425() {
                    padding: 10px !important;
                    margin: 0 0 0 10px;
                }

                @include responsive_768() {
                    padding: 10px !important;
                    margin: 0 0 0 10px;
                }
            }

            &__btn-refresh,
            &__btn-delete-all {
                margin-left: 4px;
                padding: 4px 8px;
                border: none;
                outline: none;
                background-color: transparent;
                font-size: 1rem;
                cursor: pointer;
                text-transform: capitalize;

                i {
                    margin-right: 4px;
                }

                &:hover {
                    color: var(--login-cta-hover);
                }
            }

            &__search {
                padding-right: 6px;
                border-right: 1px solid var(--dark-grey);

                @include responsive_425() {
                    display: none;
                }

                @include responsive_768() {
                    display: none;
                }

                button {
                    border-color: var(--primary-color);
                    background-color: var(--primary-color);
                    opacity: 0.8;
                    border: transparent;

                    &:hover {
                        opacity: 1;
                        border: transparent;
                    }
                }
            }

            &__menu-btn {
                border: none;
                background-color: transparent;
                line-height: 1;

                @include responsive_992() {
                    display: none;
                }

                @include responsive_1024() {
                    display: none;
                }

                @include responsive_1200() {
                    display: none;
                }

                @include responsive_1320() {
                    display: none;
                }
            }
        }

        .border {
            border: 1px solid var(--dark-grey);
        }
    }

    .list-file-group {
        position: relative;
    }
}
